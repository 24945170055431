export default {
  state () {
    return {
      latitude: "",
      longitude: ""
    }
  },
  mutations: {
    updateLocation (state, payload) {
      state.latitude = payload.lat;
      state.longitude = payload.lng;
    },
  }
}