<template>
  <div class="container-faq">
    <v-app-bar color="primary">
      <v-app-bar-nav-icon @click="$router.go(-1)">
        <v-icon>close</v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title>FAQ</v-toolbar-title>
    </v-app-bar>

    <v-expansion-panels accordion :flat="true">
      <v-expansion-panel v-for="question in questions" :key="question.id">
        <v-expansion-panel-header>{{question.title}}</v-expansion-panel-header>
        <v-expansion-panel-content>
          {{question.response}}
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <div class="footer">
      <a
        target="_blank"
        href="mailto:tiago@guiamaestro.io"
      >
        <v-icon color="black" size="25px">mail_outline</v-icon>
      </a>

      <a
        target="_blank"
        href="https://www.instagram.com/instagram/"
      >
        <img src="@/assets/instagram.svg" alt="">
      </a>
    </div>
  </div>
</template>

<script>
import CommonQuestionsService from "@/services/common.questions.service";

export default {
  name: "Faq",
  data: () => ({
    questions: [],
  }),
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        const response = await CommonQuestionsService.getAll();

        this.questions = response.data;
      } catch (error) {
        this.$router.go(-1);

        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss">
.container-faq {
  height: 100vh;

  overflow-y: scroll;

  .header {
    padding: 34px 50px 30px 34px;
  }

  .title {
    font-weight: 700;
    font-size: 47px;
    line-height: 56px;

    color: rgba(#000, 0.87);

    margin-top: 10px;
  }

  .v-expansion-panels {
    margin-top: 10px;
  }

  .v-expansion-panel {
    border-bottom: 1px solid #d4d4d4;
  }

  .footer {
    display: flex;
    justify-content: space-evenly;

    margin-top: 60px;

    a {
      width: 56px;
      height: 56px;

      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 100%;

      border-bottom: none;

      background-color: rgba(#f7f2d6, 0.8);

      margin: 10px;

      text-decoration: none;
      cursor: pointer;

      img {
        width: 25px;

        color: rgba(#000, 1.0) !important;
      }
    }
  }
}
</style>