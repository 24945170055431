<template>
  <div class="container-list">
    <div class="header">
      <img src="@/assets/logo_small.svg" alt="Guia maestro" />
    </div>

    <div class="body">
      <div class="row">
        <p class="title">Ofertas de hoje</p>

        <div class="select">
          <v-select
            v-model="order"
            :items="orders"
            label="Ordenar"
            prepend-icon="filter_list"
            color="black"
          />
        </div>
      </div>

      <div class="list" v-if="loaded && offers.length !== 0">
        <item-offer :offer="offer" v-for="offer in offers" :key="offer.id" />
      </div>

      <div v-else-if="!loaded">
        <v-skeleton-loader
          :key="display"
          v-for="display in [1, 2, 3]"
          type="list-item-avatar"
        />
      </div>

      <div class="not-offers" v-else>
        <p class="description">poxa, não temos ofertas disponíveis :(</p>
      </div>

      <div class="footer">
        <p class="sublink" @click="changeCompany()">trocar empresa</p>
      </div>
    </div>

    <modal-company />
  </div>
</template>

<script>
import ModalCompany from "@/components/ModalCompany";
import ItemOffer from "@/components/ItemOffer";

import OffersService from "@/services/offers.service";

export default {
  name: "list-offers",
  metaInfo() {
    return {
      title: "Ofertas",
    };
  },
  data: function () {
    return {
      offers: [],
      loaded: false,
      order: null,
      orders: [
        {
          text: "Distancia",
          value: "distance",
        },
        {
          text: "Nome",
          value: "name",
        },
      ],
    };
  },
  components: {
    ModalCompany,
    ItemOffer,
  },
  computed: {
    company() {
      return this.$store.state.company;
    },
    location() {
      return this.$store.state.location;
    },
  },
  mounted() {
    this.getPosition();
    this.initialize();
  },
  watch: {
    company: function () {
      this.initialize();
    },
    order: function (newVal) {
      if (newVal === "distance") {
        console.log(this.offers.sort((a, b) => {
          return a.distance < b.distance ? -1 : a.distance > b.distance ? 1 : 0;
        }));
        this.offers = this.offers.sort((a, b) => {
          return a.distance < b.distance ? -1 : a.distance > b.distance ? 1 : 0;
        });
      } else {
        this.offers = this.offers.sort((a, b) => {
          return a.advertiser.name < b.advertiser.name ? -1
            : a.advertiser.name > b.advertiser.name? 1
            : 0;
        });
      }
    },
  },
  methods: {
    getPosition() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.$store.commit("updateLocation", {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        });
      }
    },
    async initialize() {
      if (!this.company) {
        this.$store.commit("showModal", "modal-company");
      } else {
        try {
          const response = await OffersService.getAll(
            this.company.id,
            this.location
          );

          this.loaded = true;

          this.offers = response.data;
        } catch (error) {
          console.error(error);
        }
      }
    },
    changeCompany() {
      this.loaded = false;
      this.offers = [];

      this.$store.commit("updateCompany", null);
      this.$store.commit("showModal", "modal-company");
    },
  },
};
</script>

<style lang="scss">
.container-list {
  height: 100%;

  .header {
    width: 100%;

    padding: 10px;

    display: flex;
    justify-content: center;

    background-color: #f7f2d6;

    img {
      width: 80px;
    }
  }

  .v-tabs-bar.primary .v-tab,
  .v-tabs-bar.primary .v-tabs-slider {
    color: black;
  }

  .body {
    position: relative;

    max-width: 700px;

    margin: auto;
    padding: 0px 15px;

    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        margin-bottom: 0px;

        max-width: 100%;

        color: rgba(#000, 0.87);

        @media (max-width: 600px) {
          font-size: 14px !important;
        }
      }

      margin: 20px 0px;
      padding: 0px 10px;

      .select {
        width: 160px;

        .v-text-field__details {
          display: none;
        }

        .v-text-field {
          padding-top: 0px;
          margin-top: 0px;
        }
      }
    }

    .list {
      padding: 0px;
    }

    .not-offers {
      display: flex;
      align-items: center;
      justify-content: center;

      margin-top: 30px;

      .description {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;

        text-align: center;

        margin-bottom: 0px;

        color: rgba(#000, 0.6);

        @media (max-width: 600px) {
          font-size: 12px;
        }
      }
    }

    .footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      margin-top: 60px;

      p {
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;

        text-align: center;
        text-decoration: underline;

        margin-bottom: 0px;

        color: rgba(#000, 0.2);
        cursor: pointer;

        @media (max-width: 600px) {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
