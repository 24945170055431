export default {
  state () {
    return {
      offers: [],
    }
  },
  mutations: {
    addFavorite (state, payload) {
      state.offers.push(payload);
    },
    removeFavorite (state, payload) {
      state.offers = state.offers.filter((offer) => offer !== payload);
    },
  }
}