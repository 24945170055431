export default {
  state () {
    return {
      show: ""
    }
  },
  mutations: {
    showModal (state, payload) {
      state.show = payload;
    },
    closeModal (state) {
      state.show = "";
    }
  }
}