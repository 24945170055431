import instance from './base.service';

class OffersService {
  getAll(id, location) {
    let url = `/api/companies/${id}/offers.json?lat=${location.latitude}&lng=${location.longitude}`;
    return instance.get(url)
  }

  show(company_id, id, location) {
    return instance.get(`/api/companies/${company_id}/offers/${id}.json?lat=${location.latitude}&lng=${location.longitude}`)
  }
}

export default new OffersService();
