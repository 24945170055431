import Vue from 'vue';

import App from './App.vue';
import router from './router';

import store from './store';

import "./stylesheets/main.scss";

import vuetify from './plugins/vuetify';
import '@babel/polyfill';
import '@mdi/font/css/materialdesignicons.css'

Vue.config.productionTip = false;

import VueMeta from 'vue-meta';

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
