import Vue from 'vue'
import VueRouter from 'vue-router'

import ListOffers from '../views/Offers/List'
import Favorites from '../views/Favorites'
import Faq from '../views/Faq'
import Details from '../views/Offers/Details'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'ListOffers',
    component: ListOffers
  },
  {
    path: '/favorites',
    name: 'Favorites',
    component: Favorites
  },
  {
    path: '/faq',
    name: 'Faq',
    component: Faq
  },
  {
    path: '/:id',
    name: 'Details',
    component: Details
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router
