<template>
  <div class="container-details">
    <v-app-bar color="primary" fixed>
      <v-app-bar-nav-icon @click="$router.go(-1)">
        <v-icon>close</v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title>Oferta</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-icon @click="handleFavorite()">{{isFavorite ? 'favorite' : 'favorite_border'}}</v-icon>
    </v-app-bar>

    <img :src="offer.advertiser.image_url" class="image" />

    <div class="container-offer">
      <img class="logo" :src="offer.advertiser.icon_url" />

      <div class="informations">
        <h2 class="name">{{ offer.advertiser.name }}</h2>
        <h3 class="description">{{ offer.advertiser.description }}</h3>
      </div>

      <div class="location description">
        <v-icon>place</v-icon>
        <span>
          {{ offer.distance && offer.distance.toFixed(1) }}km
        </span>
      </div>
    </div>

    <div class="social">
      <a
        target="_blank"
        v-if="offer.advertiser.facebook_url"
        :href="offer.advertiser.facebook_url"
      >
        <img src="@/assets/facebook.svg" alt="">
      </a>

      <a
        target="_blank"
        v-if="offer.advertiser.instagram_url"
        :href="offer.advertiser.instagram_url"
      >
        <img src="@/assets/instagram.svg" alt="">
      </a>
    </div>

    <div class="informations">
      <p class="title-details">Oferta {{offer.fixed ? "fixa" : `de hoje, ${currentDay} (${ date })`}}</p>
      <p class="subtitle-details">
        {{ offer.note }}
      </p>

      <p class="title-details">Observacoes</p>
      <p class="subtitle-details">
        {{ offer.description }}
      </p>

      <p class="title-details">Telefone</p>
      <p class="subtitle-details">
        {{ offer.advertiser.phone }}
      </p>

      <p class="title-details">Endereco</p>
      <p class="subtitle-details">
        {{ offer.advertiser.location }}
      </p>
    </div>

    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3603.0526283948416!2d-49.281638484504164!3d-25.436503983785453!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dce47470bf1f4b%3A0x7ad5f5d70fcf26f5!2sR.%20Comendador%20Ara%C3%BAjo%2C%20431%20-%20Centro%2C%20Curitiba%20-%20PR%2C%2080420-000!5e0!3m2!1spt-BR!2sbr!4v1619449768859!5m2!1spt-BR!2sbr"
      style="border: 0"
      allowfullscreen=""
      loading="lazy"
    ></iframe>
  </div>
</template>

<script>
import OffersService from "@/services/offers.service";

export default {
  name: "details-offers",
  metaInfo() {
    return {
      title: `${this.offer.note} - ${this.offer.advertiser.name}`,
    }
  },
  data() {
    return {
      offer: {
        advertiser: {},
      },
      days: [
        "domingo",
        "segunda-feira",
        "terça-feira",
        "quarta-feira",
        "quinta-feira",
        "sexta-feira",
        "sábado",
      ],
    };
  },
  computed: {
    company() {
      return this.$store.state.company;
    },
    id() {
      return this.$route.params.id;
    },
    location() {
      return this.$store.state.location;
    },
    currentDay() {
      return this.days[Number(new Date().getDay())];
    },
    date() {
      const d = new Date();
      const month = d.getMonth() + 1;

      return `${d.getDate()}/${ month.toString().length === 1 ? `0${month}` : month}`;
    },
    isFavorite() {
      return this.$store.state.favorites.offers.includes(this.offer.id);
    },
  },
  mounted() {
    this.initialize();
  },
  watch: {
    company: function () {
      this.initialize();
    },
  },
  methods: {
    async initialize() {
      try {
        const response = await OffersService.show(
          this.company.id,
          this.id,
          this.location
        );

        this.offer = response.data;
      } catch (error) {
        this.$router.go(-1);

        console.error(error);
      }
    },
    async handleFavorite() {
      if(!this.isFavorite) {
        this.$store.commit('addFavorite', this.offer.id)
      } else {
        this.$store.commit('removeFavorite', this.offer.id)
      }
    },
  },
};
</script>

<style lang="scss">
.container-details {
  max-width: 500px;

  margin: auto;

  margin-top: 75px;

  .image {
    width: 90%;
    height: auto;

    margin-left: 5%;

    border-radius: 10px;
  }

  .social {
    display: flex;
    justify-content: space-evenly;

    a {
      width: 56px;
      height: 56px;

      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 100%;

      border-bottom: none;

      background-color: rgba(#f7f2d6, 0.8);

      margin: 10px;

      text-decoration: none;
      cursor: pointer;

      img {
        width: 25px;

        color: rgba(#000, 1.0) !important;
      }
    }
  }

  .informations {
    padding: 20px;

    .title-details {
      font-weight: 500;
      font-size: 19px;
      line-height: 25px;

      margin-bottom: 2px;

      @media (max-width: 600px) {
        font-size: 17px;
      }
    }

    .subtitle-details {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      margin-bottom: 10px;

      color: rgba(#000000, 0.87);

      @media (max-width: 600px) {
        font-size: 14px;
      }
    }
  }

  iframe {
    border-radius: 10px;

    margin-left: 5%;

    margin-bottom: 20px;

    width: 90%;
    height:200px;
  }
}
</style>
