<template>
  <v-app>
    <v-main class="containers">
      <transition
        name="fade"
        mode="out-in"
        @beforeLeave="beforeLeave"
        @enter="enter"
        @afterEnter="afterEnter"
      >
        <router-view />
      </transition>
    </v-main>

    <v-bottom-navigation
      color="secondary"
      v-if="pages_shows.includes(route)"
      fixed
      height="50"
    >
      <v-btn to="/">
        Ofertas

        <v-icon>list</v-icon>
      </v-btn>

      <v-btn to="/favorites">
        Favoritos

        <v-icon>favorite</v-icon>
      </v-btn>

      <v-btn to="/faq">
        Sobre

        <v-icon>help_outline</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-app>
</template>

<script>
export default {
  name: "App",
  metaInfo() {
    return {
      title: "dashboard",
      titleTemplate: "%s | Guia maestro",
      htmlAttrs: {
        lang: "pt-BR",
        amp: false,
      },
    };
  },
  data: function () {
    return {
      pages_shows: ["ListOffers", "Favorites", "Faq"],
    };
  },
  computed: {
    route() {
      return this.$route.name;
    },
  },
  methods: {
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);
      element.style.height = this.prevHeight;
      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    },
  },
};
</script>

<style lang="scss">
.containers {
  height: calc(100% - 100px);
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.fade-transform-enter-active,
.fade-transform-leave-active {
  transition: all 0.2s;
}
.fade-transform-enter {
  opacity: 0;
  -webkit-transform: translateX(-30px);
  transform: translateX(-30px);
}
.fade-transform-leave-to {
  opacity: 0;
  -webkit-transform: translateX(30px);
  transform: translateX(30px);
}
</style>
