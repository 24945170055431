<template>
  <div class="container-offer" @click="navigationToDetails()">
    <img class="logo" :src="offer.advertiser.icon_url" />

    <div class="informations">
      <h2 class="name">{{ offer.advertiser.name }}</h2>
      <p class="description">{{ offer.description }}</p>
    </div>

    <p class="location description">
      <v-icon>place</v-icon>
      <span>
        {{ offer.distance.toFixed(1) }}km
      </span>
    </p>
  </div>
</template>

<script>
  export default {
    name: "item-offer",
    props: {
      offer: {
        id: String,
        name: String,
        description: String,
        distance: String,
        advertiser: Array,
      },
    },
    methods: {
      navigationToDetails() {
        this.$router.push({
          name: "Details",
          params: {
            id: this.offer.id,
          },
        });
      },
    },
  };
</script>

<style lang="scss">
.container-offer {
  padding: 10px;

  border-bottom: 1px solid #f2f2f2;

  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;

  .logo {
    width: 72px;
    height: 72px;

    background: #c4c4c4;

    border-radius: 50%;

    @media (max-width: 600px) {
      width: 52px;
      height: 52px;
    }
  }

  .informations {
    flex: 1;

    margin-left: 20px;

    .name {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;

      margin-bottom: 0px;

      max-width: 100%;

      color: rgba(#000, 0.87);

      @media (max-width: 600px) {
        font-size: 14px;
      }
    }
  }

  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    margin-bottom: 0px;

    color: rgba(#000, 0.6);

    @media (max-width: 600px) {
      font-size: 12px;
    }

    &.location {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-items: center;
      min-width: 40px;
      margin-left: 10px;

      span {
        display: block;
        width: 100%;
        text-align: center;
      }

      i.v-icon {
        display: block;
      }
    }
  }
}
</style>
