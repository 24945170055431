import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from "vuex-persistedstate";

import modal from "./modules/modal";
import location from "./modules/location";
import favorites from "./modules/favorites";

Vue.use(Vuex)

const store = new Vuex.Store({
  namespaced: true,
  state () {
    return {
      company: null
    }
  },
  mutations: {
    updateCompany (state, payload) {
      state.company = payload;
    }
  },
  modules: {
    modal,
    location,
    favorites
  },
  plugins: [createPersistedState({
    key: 'guiamaestro',
  })],
})

export default store;
