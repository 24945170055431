<template>
  <div :class="['modal-backdrop', show]">
    <div class="modal">
      <img src="@/assets/welcome-banner.png" class="header" />

      <div class="body">
        <div>
          <p class="title">Bem vindo ao Guia Maestro</p>

          <p class="subtitle">
            Ofertas exclusivas pertinho de você
          </p>

          <p class="subtitle">
            Para começar, selecione abaixo
          </p>
        </div>

        <div>
          <v-combobox
            v-model="select"
            :items="companies_filter"
            label="Onde você trabalha?"
            color="black"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import companiesService from "@/services/companies.service";

export default {
  name: "modal-company",
  data: function () {
    return {
      companies: [],
      select: "",
    };
  },
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        const response = await companiesService.getAll();

        this.companies = response.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    show() {
      return this.$store.state.modal.show === "modal-company"
        ? "show-modal"
        : "close-modal";
    },
    companies_filter() {
      return this.companies.map((company) => {
        return {
          text: company.name,
          value: company.id,
        };
      });
    },
  },
  watch: {
    select: function (val) {
      this.$store.commit(
        "updateCompany",
        this.companies.find((c) => c.id === val.value)
      );
      this.$store.commit("closeModal");
    },
  },
};
</script>

<style lang="scss">
.show-modal {
  display: flex;
}

.close-modal {
  display: none;
}

.modal-backdrop {
  position: absolute;

  z-index: 999;

  top: 0;
  left: 0;

  width: 100%;
  height: 100vh;

  align-items: center;
  justify-content: center;

  background: #00000079;

  .modal {
    width: 320px;
    height: 420px;

    background-color: #fff;
    border-radius: 5px;

    .header {
      width: 100%;
      height: 40%;

      border-radius: 5px;
    }

    .body {
      padding: 0px 20px;

      height: 60%;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      .title {
        font-weight: 500;
        font-size: 19px;
        line-height: 25px;

        margin-bottom: 0px;

        text-align: center;
      }

      .subtitle {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        text-align: center;

        color: rgba(#000000, 0.87);

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
